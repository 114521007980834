import { Sparkle28Filled } from "@fluentui/react-icons";
import styles from "./AnswerIcon.module.css";
export const AnswerIcon = () => {
    return (
        <div className={styles.iconContainer}>
            <Sparkle28Filled primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Answer logo" />
        </div>
    );
};

// import { Sparkle28Filled } from "@fluentui/react-icons";
// import { animated, useSpring, easings } from "@react-spring/web";

// export const AnswerIcon = () => {
//     const [{ transform }] = useSpring(() => ({
//         from: { transform: "rotate(0deg)" },
//         to: { transform: "rotate(360deg)" }, // Rotate fully
//         config: {
//             duration: 2000,
//             easing: easings.easeInOutQuart
//         },
//         loop: true // Continuously rotate
//     }));

//     return (
//         <animated.div style={{ transform }} className={styles.iconWrapper}>
//             <Sparkle28Filled primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Answer logo" />
//         </animated.div>
//     );
// };
