import { Example } from "./Example";

import styles from "./Example.module.css";

// const DEFAULT_EXAMPLES: string[] = [
//     "Tell me the EPR fee schedule for British Columbia?",
//     "Tell me the EPR fee schedule for Saskatchewan?",
//     "Tell me which materials are included in Plastics for all provinces?",
//     "Tell me which materials are included for Paper for all provinces?",
//     "Tell me which province has the highest fee for each individual item of material?",
//     "Tell me the difference between PP categories for BC and SK?",
//     "Could you mention which is the legislation that manages EPR in BC?",
//     "Could you mention which is the legislation that manages EPR in SK?",
//     "Could you mention which regulation manages EPR in all provinces?",
//     "Could you summarize the EPR Plan for BC?",
//     "Could you summarize the EPR Plan for SK?",
//     "What is a Program Operator?",
//     "Could you identify the Operators for all Provinces?",
//     "Could you tell me the latest EPR Operator Plan for BC?",
//     "Could you tell me the latest EPR Operator Plan for SK?"
// ];

const DEFAULT_EXAMPLES_1: { title: string; questions: string[] } = {
    title: "What is EPR",
    questions: [
        "Can you define Extended Producer Responsibility (EPR), explain its role in shifting waste management, and highlight its key principles?",
        "Can you define Packaging and Paper Products (PPP), and discuss the regulations surrounding them?",
        "Can you define Single-Use Plastics (SUPs), and discuss the regulations surrounding them?",
        "Could you define Beverage Containers, highlight their importance, and outline the challenges associated with them?",
        "Can you provide a definition of Program Operators and mention contributors to Extended Producer Responsibility (EPR) plans?",
        "Could you explain the function of Fee Schedules and emphasize their importance?"
    ]
};

const DEFAULT_EXAMPLES_2: { title: string; questions: string[] } = {
    title: "Regulatory Framework",
    questions: [
        "Which provinces have established fee schedules for their EPR programs for Packaging and Paper Products (PPP)?",
        "What are the program plans, and implementation dates, of Extended Producer Responsibility for Packaging and Paper Products (PPP) in each Canadian province or territory?",
        "In terms of circular approaches to handling Packaging and Paper Products (PPP), which Canadian provinces and territories are leading the way?",
        "For the province currently undergoing consultation for the Extended Producer Responsibility (EPR) program related to Packaging and Paper Products (PPP), what are the expected timeline for implementation?"
    ]
};

const DEFAULT_EXAMPLES_3: { title: string; questions: string[] } = {
    title: "Operational Aspects and Timeline",
    questions: [
        "In which provinces is the Government positioned to enforce bans and restrictions, with specific program plans and applicable fee schedules for Extended Producer Responsibility (EPR) programs",
        "For which provinces or territories are fee schedules for Extended Producer Responsibility (EPR) programs not currently published?",
        "Which provinces are currently in transition, moving their Shared Responsibility Program to an Extended Producer Responsibility (EPR) model?",
        "Which provinces or territories have Extended Producer Responsibility (EPR) programs for Product Stewardship Programs (PPP) or Beverage Containers with program plans marked as in-transition?"
    ]
};

export const DEFAULT_EXAMPLES: { title: string; questions: string[] }[] = [DEFAULT_EXAMPLES_1, DEFAULT_EXAMPLES_2, DEFAULT_EXAMPLES_3];

const GPT4V_EXAMPLES: string[] = [];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <>
            <div className={styles.ExampleCategoryWrap}>
                {DEFAULT_EXAMPLES.map((category, i) => (
                    <div key={i} className={styles.ExampleCategory}>
                        <h3 className={styles.ExampleCategoryTitle}>{category.title}</h3>
                        <ul className={styles.examplesNavList}>
                            {category.questions.map((question, i) => (
                                <li key={i}>
                                    <Example text={question} value={question} onClick={onExampleClicked} />
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
                <ul className={styles.examplesNavList}></ul>
            </div>
        </>
    );
};
