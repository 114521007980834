import { Example } from "./ExampleSidebar";

import styles from "./ExampleListSidebar.module.css";
import { DEFAULT_EXAMPLES } from "./ExampleList";

const GPT4V_EXAMPLES: string[] = [];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleListSidebar = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <div className={styles.ExampleCategorySidebarWrap}>
            {DEFAULT_EXAMPLES.map((category, i) => (
                <div key={i} className={styles.ExampleCategorySidebar}>
                    <h5 className={styles.ExampleCategoryTitleSidebar}>{category.title}</h5>
                    <ul className={styles.examplesNavListSidebar}>
                        {category.questions.map((question, i) => (
                            <li key={i}>
                                <Example text={question} value={question} onClick={onExampleClicked} />
                            </li>
                        ))}
                    </ul>
                </div>
            ))}

            <ul className={styles.examplesNavListSidebar}></ul>
        </div>
    );
};
