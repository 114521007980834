import { Outlet, NavLink, Link } from "react-router-dom";

import Logo from "../../assets/Logotipo-The-Owl-3.png";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";

const Layout = () => {
    const showSettings = (event: any) => {
        event.preventDefault();
    };

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <div className={styles.githubHeaderLogoWrapper}>
                        <img src={Logo} alt="OWL logo" className={styles.githubHeaderLogo} />
                    </div>
                    <h2 className={styles.headerTitleSecondary}>EPR Chatbot</h2>
                    <div className={styles.headerWrapper}>
                        <h2 className={styles.headerTitleMain}>The OWL Solutions: Extended Producer Responsibility (EPR) Chatbot</h2>
                    </div>
                    <button className={styles.headerButton} onClick={() => window.open("https://www.saveatonne.org", "_blank")}>
                        Save a Tonne
                    </button>
                    {useLogin && <LoginButton />}
                </div>

                {/* <div className={styles.headerContainer}>
                    <div className={styles.githubHeaderLogoWrapper}>
                        <img src={Logo} alt="OWL logo" className={styles.githubHeaderLogo} />
                    </div>
                    <div className={styles.headerWrapper}>
                        <h2 className={styles.headerTitleMain}>The OWL Solutions: Extended Producer Responsibility (EPR) Chatbot</h2>
                        <button className={styles.headerButton} onClick={() => window.open("https://www.saveatonne.org", "_blank")}>
                            Save a Tonne
                        </button>

                        <h2 className={styles.headerTitleSecondary}>EPR Chatbot</h2>
                    </div>

                    {useLogin && <LoginButton />}
                </div> */}
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
